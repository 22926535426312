@import "../../styles/base/colors.scss";
@import "../../styles/base/variables.scss";

.container {
    width: 100%;
    max-height: 126px;
    min-height: 128px;
    object-fit: contain;
    max-width: 1018px;
}
